<template>
  <v-menu
    :close-on-content-click="true"
    :nudge-width="140"
    :nudge-bottom="10"
    offset-y
    z-index="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        color="blue-grey lighten-5"
        class="ml-2 pr-1"
        style="height: 29px !important;overflow: visible !important"
        label
        link
        v-bind="attrs"
        v-on="on"
      >                          
      <span>Más</span>  
      <v-icon 
        size="22" 
        class="ml-1"
        v-text="false ? 'mdi-menu-up' : 'mdi-menu-down' "
      />          
      </v-chip>
    </template>
    <v-card>
      <v-card-text class="px-0">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-list
              dense
              class="py-0"
            >
              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action class="mr-4">
                    <v-checkbox
                      v-model="selected_btn"                                 
                      color="icono" 
                      :input-value="active" 
                      value="BtnGeo"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Geolocalización</v-list-item-title>                            
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-list-item>
                <template v-slot:default="{ active }">
                  <v-list-item-action class="mr-4">
                    <v-checkbox
                      v-model="selected_btn"
                      color="icono" 
                      :input-value="active" 
                      value="BtnMotivo" 
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>Motivo</v-list-item-title>                            
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-btn
              class="ma-1 "
              color="blue-grey"
              plain
              @click="selected_btn = ''"
            >
              <span>Borrar selección</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'BtnPedido',
  props:{
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      selected_btn: this.value,
    }
  },
  watch:{
    value(val, oldVal) {
      if(val === oldVal) return

      this.selected_btn = val;
    },
    selected_btn(val, oldVal) {
      if(val === oldVal) return
      
      this.$emit('input', val);
    }
  }
}
</script>